<template>
    <main id="about_page">
        <div class="about-body">
            <div class="about-content box">
                <p>The Registrar of Joint Stock Companies and Firms (RJSC) is the sole authority which facilitates formation of companies
                etc.; and keeps track of all ownership related issues as prescribed by the laws in Bangladesh.
                The Registrar is the authority of the Office of the Registrar of Joint Stock Companies and Firms, Bangladesh.</p>
                <strong class="py-2">RJSC deals with the following types of entities:</strong>
                <b-row>
                    <b-col sm="4">
                        <ul class="entities_list">
                            <li>Private companies</li>
                            <li>Public companies</li>
                        </ul>
                    </b-col>
                    <b-col sm="4">
                        <ul class="entities_list">
                            <li>Foreign companies</li>
                            <li>Trade organizations</li>
                        </ul>
                    </b-col>
                    <b-col sm="4">
                        <ul class="entities_list">
                            <li>Societies Firm</li>
                            <li>Partnership firms</li>
                        </ul>
                    </b-col>
                </b-row>
                <strong class="py-2">RJSC accords registration and ensures lawful administration of the entities under the provisions of applicable act as
                under:</strong>
                <ol class="basic-list">
                    <li>Companies and Trade Organizations: Companies Act, 1994 (Amendment of Companies Act 1913)</li>
                    <li>Societies: Societies Registration Act, 1860</li>
                    <li>Partnership Firms: Partnership Act, 1932</li>
                </ol>
                <strong class="py-2">Historical Background</strong>
                <p>
                    After the partition of India, the Office of the Registrar of Joint Stock Companies & Firms under the Ministry of
                    Commerce was first set up in Chittagong, the port and 2nd biggest city of Bangladesh with some files and records of
                    Companies, Associations (Trade Organizations) and partnership firms received from Kolkata, India. The office was
                    transferred to Dhaka, the capital of Bangladesh in 1962. Currently there are around 110,000 entities registered under
                    RJSC.
                </p>
                <strong class="py-2">ORGANOGRAM AND STAFFING</strong>
                <p>
                    The Registrar is the head of RJSC.
                    Next to the Registrar, there is one (1) Deputy Registrar in Dhaka.
                    The divisional offices at Chittagong, Khulna and Rajshahi are run under the administrative control of the Registrar with
                    one (1) Assistant Registrar being In-Charge of the respective divisional office. The present RJSC organogram has
                    provision of thirty nine (39) staff distributed as under:
                </p>
                <div class="flow-chart">
                    <div class="container">
                        <h4 class="level-1 rectangle">REGISTRAR</h4>
                        <ol class="level-2-wrapper">
                            <li>
                                <h5 class="level-2 rectangle">DHAKA DIVISION</h5>
                                <div class="chart-content">
                                    <ul>
                                        <li>1 x Deputy Registrar</li>
                                        <li>1 x Asstt. Registrar</li>
                                        <li>2 x Examiner of accounts</li>
                                        <li>1 x Inspector</li>
                                        <li>1 x HA/UDA</li>
                                        <li>2 x LDA-cum-Typist</li>
                                        <li>2 x MLSS</li>
                                        <li>1 x Night Guard</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <h5 class="level-2 rectangle">CHITTAGONG DIVISION</h5>
                                <div class="chart-content">
                                    <ul>
                                        <li>1 x Deputy Registrar</li>
                                        <li>1 x Asstt. Registrar</li>
                                        <li>2 x Examiner of accounts</li>
                                        <li>1 x Inspector</li>
                                        <li>1 x HA/UDA</li>
                                        <li>2 x LDA-cum-Typist</li>
                                        <li>2 x MLSS</li>
                                        <li>1 x Night Guard</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <h5 class="level-2 rectangle">KHULNA DIVISION</h5>
                                <div class="chart-content">
                                    <ul>
                                        <li>1 x Deputy Registrar</li>
                                        <li>1 x Asstt. Registrar</li>
                                        <li>2 x Examiner of accounts</li>
                                        <li>1 x Inspector</li>
                                        <li>1 x HA/UDA</li>
                                        <li>2 x LDA-cum-Typist</li>
                                        <li>2 x MLSS</li>
                                        <li>1 x Night Guard</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <h5 class="level-2 rectangle">RAJSHAHI DIVISION</h5>
                                <div class="chart-content">
                                    <ul>
                                        <li>1 x Deputy Registrar</li>
                                        <li>1 x Asstt. Registrar</li>
                                        <li>2 x Examiner of accounts</li>
                                        <li>1 x Inspector</li>
                                        <li>1 x HA/UDA</li>
                                        <li>2 x LDA-cum-Typist</li>
                                        <li>2 x MLSS</li>
                                        <li>1 x Night Guard</li>
                                    </ul>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>
                <p>
                    In near future four (4) more divisional offices will be setup at Sylhet, Barisal, Bogra and Comilla. Manpower will then
                    increase from present 39 to 58, with distribution as 20 in Dhaka, 10 in Chittagong and 4-6 in each office of Rajshahi,
                    Khulna, Sylhet, Barisal, Bogra & Comilla.
                </p>
                <strong class="py-2">RJSC BUSINESS</strong>
                <p>The business of RJSC is-</p>
                <ol class="basic-list">
                    <li>To incorporate Companies (including Trade Organization), Societies and Partnership Firms under the respective Companies
                    Act 1994, Societies Registration Act 1860 and Partnership Act 1932</li>
                    <li>To administer and enforce the relevant statutory provisions of these acts in relation to the incorporated companies
                    (including Trade Organization), societies and partnership firms.</li>
                </ol>
<!-- ====================RJSC business==================== -->
                <strong class="py-2">RJSC business is split into the following major business processes:</strong>
                <div class="section_title">
                    <h5>Name Clearance</h5>
                </div>
                <p class="pt-1">
                    This is a pre-requisite for registration of a new company (other than Foreign Company) or a society or a trade
                    organization.
                    Promoters of a new entity (company, society or trade organization) apply for, and RJSC provides name clearance for one
                    of the proposed names upon satisfaction that it does not closely match or resembles with any of the already taken names
                    (registered, booked or under the process of registration of the same entity type)
                </p>
                <div class="section_title">
                    <h5>Registration</h5>
                </div>
                <p class="pt-1">
                    Promoters of a new entity apply for, and RJSC issues a certificate of incorporation for a new entity upon satisfaction
                    that the application conforms to the provisions of the applicable act and that requisite fees are paid.
                </p>
                <div class="section_title">
                    <h5>Returns Filing</h5>
                </div>
                <p class="pt-1">
                    Registered entities are to file returns in prescribed forms & schedules, and RJSC upon satisfaction approves and
                    archives such records. There are two (2) types of returns, viz., ‘Annual Returns’ and ‘Returns for any Change in the
                    Entity’
                </p>
                <div class="section_title">
                    <h5>Issuance of certified copies</h5>
                </div>
                <p class="pt-1">
                    Anyone can apply for certified copy of record(s) of an entity. In response to any such application and after getting
                    requisite payment, RJSC issues certified copy of the historical records of an entity. Profit & loss account is however
                    open to only authorized personnel of the respective entity.
                </p>
                <div class="section_title">
                    <h5>Winding up</h5>
                </div>
                <p class="pt-1">
                    A company having resolved to or the court makes an order or Memorandum & Articles of Association provide so for winding
                    up of the company, submits to RJSC documents of winding up procedures and dissolution.
                </p>
                <div class="section_title">
                    <h5>Struck Off</h5>
                </div>
                <p class="pt-1">
                    RJSC strikes off the Register a company’s name if it is not in operation any more.
                </p>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    name: 'About',
    components: {
    }
}
</script>
<style>
.about-content{
    padding: .8rem;
}
.about-content p{
    text-align: justify;
}
.entities_list li {
    display: flex;
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    align-items: center;
    padding: 4px 0;
    box-sizing: border-box;
}
.entities_list li::before {
    content: "\272A";
    font-size: 1.2rem;
    text-align: right;
    font-weight: bold;
    min-width: 50px;
    padding-right: 12px;
    align-self: flex-start;
    background-image: linear-gradient(0deg, rgb(3, 212, 80) 21%, rgb(4, 108, 48) 34%, rgb(147, 255, 64) 64%, rgb(0, 255, 204) 78%, rgb(8, 149, 52) 91%);;
    background-attachment: fixed;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* flow chart style */
.flow-chart{
    margin: 2rem 0;
}
.flow-chart .rectangle {
    position: relative;
    text-align: center;
    padding: 8px;
    border: 1px dotted;
}

.flow-chart .level-1 {
    color: #f9f9f9;
    width: 50%;
    margin: 0 auto 40px;
    background: var(--rjsc-brand);
}

.flow-chart .level-1::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 20px;
    background: var(--dark);
}

.flow-chart .level-2-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
}

.flow-chart .level-2-wrapper::before {
    content: "";
    position: absolute;
    top: -20px;
    left: 12.2%;
    width: 75.5%;
    height: 2px;
    background: var(--dark);
}

.flow-chart .level-2-wrapper::after {
    display: none;
    content: "";
    position: absolute;
    left: -20px;
    bottom: -20px;
    width: calc(100% + 20px);
    height: 2px;
    background: var(--dark);
}

.flow-chart .level-2-wrapper li {
    position: relative;
}

.flow-chart .level-2-wrapper>li::before {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 20px;
    background: var(--dark);
}
.flow-chart .level-2-wrapper li .level-2 {
    font-size: 15px;
}

.flow-chart .level-2::after {
    display: none;
    content: "";
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translate(-100%, -50%);
    width: 20px;
    height: 2px;
    background: var(--dark);
}
.flow-chart .chart-content{
    margin-top: 5px;
    padding: 5px;
    border: 1px dotted;
}
</style>
